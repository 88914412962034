export const RECURRENCE_REPEATERS = [
  {
    label: 'Day',
    value: 'daily',
  },
  {
    label: 'Week',
    value: 'weekly',
  },
  {
    label: 'Month',
    value: 'monthly',
  },
  {
    label: 'Does Not Repeat',
    value: 'never',
  },
];

export const DEVICES_LIST_HEADER = [
  { id: 'photo', isSortable: false, label: 'Photo', isVisible: true, style: { minWidth: 'auto' } },
  { id: 'assetId', isSortable: true, label: 'ID', isVisible: true },
  { id: 'category', isSortable: true, label: 'Category', isVisible: true },
  { id: 'make', isSortable: true, label: 'Make', isVisible: true },
  { id: 'model', isSortable: true, label: 'Model', isVisible: true },
  { id: 'lastDate', isSortable: true, label: 'Last Service', isVisible: true },
  { id: 'assignedUser', isSortable: true, label: 'Assigned User', isVisible: true },
  { id: 'dueDate', isSortable: false, label: 'Service Due', isVisible: true },
  { id: 'deviceUse', isSortable: true, label: 'Device Use', isVisible: true },
  { id: 'facility', isSortable: true, label: 'Facility', isVisible: true },
  { id: 'area', isSortable: true, label: 'Area', isVisible: true },
  {
    id: 'actions',
    isSortable: false,
    label: '',
    isVisible: true,
    style: { minWidth: 'auto', paddingRight: 0 },
  },
];

const columnStyles = {
  minWidth: 'auto',
  wordBreak: 'normal',
};
const textColumnStyles = {
  minWidth: 160,
  maxWidth: 'none',
  wordBreak: 'break-word',
};

const COMPLIANCE_COLUMNS = [
  {
    id: 'taskType',
    isSortable: true,
    label: 'Frequency',
    isVisible: true,
    style: columnStyles,
  },
  {
    id: 'taskName',
    isSortable: true,
    label: 'Name',
    isVisible: true,
    style: textColumnStyles,
  },
  {
    id: 'taskDescription',
    isSortable: true,
    label: 'Description',
    isVisible: true,
    style: textColumnStyles,
  },
  {
    id: 'runTaskNote',
    isSortable: true,
    label: 'Notes',
    isVisible: true,
    style: columnStyles,
  },
  {
    id: 'runTaskImage',
    isSortable: false,
    label: 'Image/PDF',
    isVisible: true,
    style: columnStyles,
  },
  {
    id: 'runTaskIsCorrected',
    isSortable: true,
    label: 'Corrective Action Taken',
    isVisible: true,
    style: columnStyles,
  },
  {
    id: 'taskPerformedOn',
    isSortable: false,
    label: 'Performed On',
    isVisible: true,
    style: columnStyles,
  },
  {
    id: 'taskCategory',
    isSortable: true,
    label: 'Task Category',
    isVisible: true,
    style: columnStyles,
  },
];

const COMPLETE_COLUMNS = [
  ...COMPLIANCE_COLUMNS,
  {
    id: 'runTaskCompletedBy',
    isSortable: true,
    label: 'Completed By',
    isVisible: true,
    style: columnStyles,
  },
  {
    id: 'runTaskCompletionDate',
    isSortable: true,
    label: 'Completion Date',
    isVisible: true,
    style: columnStyles,
  },
];

const INCOMPLETE_COLUMNS = [
  ...COMPLIANCE_COLUMNS,
  {
    id: 'runTaskCompletedBy',
    isSortable: true,
    label: 'Incomplete By',
    isVisible: true,
    style: columnStyles,
  },
  {
    id: 'runTaskCompletionDate',
    isSortable: true,
    label: 'Incomplete Date',
    isVisible: true,
    style: columnStyles,
  },
];

export const COMPLIANCE_LIST_HEADER = {
  in_progress: [
    {
      id: 'taskType',
      isSortable: true,
      label: 'Frequency',
      isVisible: true,
      style: columnStyles,
    },
    {
      id: 'taskName',
      isSortable: true,
      label: 'Name',
      isVisible: true,
      style: textColumnStyles,
    },
    {
      id: 'taskDescription',
      isSortable: true,
      label: 'Description',
      isVisible: true,
      style: textColumnStyles,
    },
    {
      id: 'runTaskAssignedTo',
      isSortable: true,
      label: 'Assigned To',
      isVisible: true,
      style: {
        minWidth: 120,
      },
    },
    {
      id: 'runTaskDueDate',
      isSortable: true,
      label: 'Due Date',
      isVisible: true,
      style: columnStyles,
    },
    {
      id: 'taskPerformedOn',
      isSortable: false,
      label: 'Performed On',
      isVisible: true,
      style: columnStyles,
    },
    {
      id: 'taskCategory',
      isSortable: true,
      label: 'Task Category',
      isVisible: true,
      style: columnStyles,
    },
  ],
  complete: COMPLETE_COLUMNS,
  incomplete: INCOMPLETE_COLUMNS,
};

export const SERVICE_PROVIDER_LIST_HEADER = [
  {
    id: 'technicianFullName',
    isSortable: true,
    label: 'Name',
    isVisible: true,
  },
  {
    id: 'technicianBusinessName',
    isSortable: true,
    label: 'Company',
    isVisible: true,
  },
  {
    id: 'technicianAddressLine',
    isSortable: true,
    label: 'Address',
    isVisible: true,
  },
  {
    id: 'technicianFileId',
    isSortable: false,
    label: 'Photo',
    isVisible: true,
    style: { minWidth: 'auto' },
  },
  {
    id: 'facilityCount',
    isSortable: true,
    label: 'Facilities',
    isVisible: true,
    style: { minWidth: 'auto', wordBreak: 'normal' },
  },
  {
    id: 'deviceCount',
    isSortable: true,
    label: 'Devices',
    isVisible: true,
    style: { minWidth: 'auto', wordBreak: 'normal' },
  },
  {
    id: 'serviceProviderStatus',
    isSortable: true,
    label: 'Status',
    isVisible: true,
    style: { textAlign: 'center' },
  },
  { id: 'location', isSortable: false, label: 'Location', isVisible: true },
  {
    id: 'actions',
    isSortable: false,
    label: '',
    isVisible: true,
    style: { minWidth: 'auto', paddingRight: 0 },
  },
];

export const COMPLETED_TASKS_SECTION_HEADER = [
  { id: 'type', label: 'Frequency', isVisible: true },
  { id: 'name', label: 'Task Name', isVisible: true },
  { id: 'description', label: 'Task Description', isVisible: true },
  { id: 'completedBy', label: 'Completed By', isVisible: true },
  { id: 'date', label: 'Date', isVisible: true },
  { id: 'time', label: 'Time', isVisible: true },
  { id: 'image', label: '', isVisible: true, style: { minWidth: 'auto', paddingRight: 0 } },
];

export const DOCUMENT_SECTION_HEADER = [
  { id: 'fileName', label: 'File', isVisible: true, style: { paddingLeft: '24px' } },
  { id: 'documentTypeCode', label: 'Document Type', isVisible: true },
  { id: 'documentVersion', label: 'Version', isVisible: true },
  { id: 'documentDescription', label: 'Description', isVisible: true },
  { id: 'deviceAssetId', label: 'Asset ID', isVisible: true },
  { id: 'deviceMake', label: 'Make', isVisible: true },
  { id: 'deviceCategory', label: 'Category', isVisible: true },
  { id: 'deviceModel', label: 'Model', isVisible: true },
  { id: 'facilityTitles', label: 'Facility', isVisible: true },
];

export const INCOMPLETE_TASKS_SECTION_HEADER = [
  { id: 'type', label: 'Frequency', isVisible: true },
  { id: 'name', label: 'Task Name', isVisible: true },
  { id: 'description', label: 'Task Description', isVisible: true },
  { id: 'assignedUser', label: 'Assigned To', isVisible: true },
  { id: 'date', label: 'Date', isVisible: true },
  { id: 'time', label: 'Due Time', isVisible: true },
];

export const DEVICE_LOGS_SECTION_HEADER = [
  { id: 'createdAt', label: 'Date', isVisible: true },
  { id: 'areaTitle', label: 'Area', isVisible: true },
  { id: 'facilityTitle', label: 'Facility', isVisible: true },
  { id: 'flowTitle', label: 'Log Type', isVisible: true },
  { id: 'initials', label: 'Person', isVisible: true },
];

export const FACILITY_LOGS_SECTION_HEADER = [
  { id: 'createdAt', label: 'Date', isVisible: true },
  { id: 'areaTitle', label: 'Area', isVisible: true },
  { id: 'deviceAssetId', label: 'Device', isVisible: true },
  { id: 'flowTitle', label: 'Log Type', isVisible: true },
  { id: 'initials', label: 'Person', isVisible: true },
];

export const LOGS_COMPLIANCE_HEADER = [
  { id: 'createdAt', label: 'Date', isVisible: true },
  { id: 'facilityTitle', label: 'Facility', isVisible: true },
  { id: 'flowTitle', label: 'Log Type', isVisible: true },
  { id: 'initials', label: 'Person', isVisible: true },
];

export const STRATEGY = {
  1: 'OEM',
  2: 'AEM',
  3: 'Warranty',
  4: 'Rental',
  5: 'Not Required',
};

export const NOT_FOUND = 'Not Found';
export const EMPTY = '-';

export const FACILITY_LINKS = {
  create: '/app/facilities/create',
  update: '/app/facilities/update',
  view: '/app/facilities/',
  // TODO:C - replace with import.meta after migrating to VITE all projects
  exportTaskHistory: `${
    process.env.REACT_APP_DOMAIN || process.env.VITE_DOMAIN
  }device/default/export-facility-task-history`,
};

export const NO_TASKS = 'No tasks.';
export const NO_DEVICES = 'No devices.';

export default {
  frequencies: '/app/frequency/default/index',
  devicesUpload: '/app/device/default/upload',
  exportDevices: '/app/device/default/export-device',
  // TODO:C - replace with import.meta after migrating to VITE all projects
  exportWOHistory: `${
    process.env.REACT_APP_DOMAIN || process.env.VITE_DOMAIN
  }device/default/export-devices-history`,
  exportTaskHistory: `${
    process.env.REACT_APP_DOMAIN || process.env.VITE_DOMAIN
  }device/default/task-device-history`,
  createDevices: '/app/devices/create',
  update: (id) => `/app/devices/update/${id}`,
  view: (id) => `/app/devices/${id}`,
  requestRepairWithId: ({ deviceId, flowId }) => `/app/work-orders/request/device/${flowId}?ids=${deviceId}`,
  deviceRequestRepairTechnician: ({ deviceId, flowId, userId, signature }) =>
    `/app/work-orders/request/device/${flowId}?ids=${deviceId}&userId=${userId}&signature=${signature}`,
  areaRequestRepairTechnician: ({ areaIds, flowId, userId, signature }) =>
    `/app/work-orders/request/area/${flowId}?ids=${areaIds}&userId=${userId}&signature=${signature}`,
  requestReport: (deviceId) => `/app/work-orders/report/${deviceId}`,
  // TODO:C - replace with import.meta after migrating to VITE all projects
  download: (id) =>
    `${process.env.REACT_APP_DOMAIN || process.env.VITE_DOMAIN}device/default/report?id=${id}`,
  reportRepair: (id) => `/app/work-orders/report/${id}`,
};

export const MAX_FILES_NUMBER = 5;

export const FULL_DAY = 'Full day';

export const TECHNICIAN_DEVICE_HEADERS = [
  { id: 'photo', isSortable: false, label: 'Photo', isVisible: true, style: { minWidth: 'auto' } },
  { id: 'assetId', isSortable: true, label: 'ID', isVisible: true },
  { id: 'makeTitle', isSortable: true, label: 'Make', isVisible: true },
  { id: 'categoryTitle', isSortable: true, label: 'Category', isVisible: true },
  { id: 'modelTitle', isSortable: true, label: 'Model', isVisible: true },
  { id: 'lastDate', isSortable: true, label: 'Last Service', isVisible: true },
  { id: 'dueDate', isSortable: false, label: 'Service Due', isVisible: true },
  {
    id: 'actions',
    isSortable: false,
    label: '',
    isVisible: true,
    style: { minWidth: 'auto', textAlign: 'right' },
  },
];

export const TECHNICIAN_FACILITY_HEADERS = [
  { id: 'photo', isSortable: false, label: 'Photo', isVisible: true, style: { minWidth: 'auto' } },
  { id: 'title', isSortable: true, label: 'Facility', isVisible: true },
  { id: 'addressOne', isSortable: false, label: 'Address', isVisible: true },
  { id: 'schedule', isSortable: false, label: 'Days / Hours', isVisible: true },
  {
    id: 'areaCount',
    isSortable: true,
    label: 'Areas',
    isVisible: true,
    style: { minWidth: 'auto', wordBreak: 'normal' },
  },
  { id: 'location', isSortable: false, label: 'Location', isVisible: true },
  {
    id: 'actions',
    isSortable: false,
    label: '',
    isVisible: true,
    style: { minWidth: 'auto', textAlign: 'right' },
  },
];
