import React from 'react';

const ConnectedDeviceIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.09276 14.0575H11.8046C12.3357 14.0575 12.7655 13.6276 12.7655 13.0965C12.7655 12.5655 12.3357 12.1356 11.8046 12.1356H8.09276C7.56168 12.1356 7.13184 12.5655 7.13184 13.0965C7.13184 13.6276 7.56168 14.0575 8.09276 14.0575Z" />
      <path d="M40.6352 26.949H40.3171V13.2471C40.3171 8.66707 36.6691 4.9418 32.1857 4.9418C27.7016 4.9418 24.0536 8.66784 24.0536 13.2471V36.3735C24.0536 40.0199 21.1491 42.987 17.5785 42.987C14.0079 42.987 11.1034 40.0199 11.1034 36.3735V21.9116H13.0171C13.5611 21.9116 14.0014 21.4619 14.0014 20.9062V18.3879H14.2639C15.9039 18.3879 17.2381 17.0252 17.2381 15.3501V9.87541C17.2381 9.31974 16.7977 8.86999 16.2537 8.86999H14.7792L14.7789 4.00541C14.7789 3.44974 14.3386 3 13.7946 3H6.4432C5.89916 3 5.45884 3.44974 5.45884 4.00541V8.8703H3.98436C3.44033 8.8703 3 9.32004 3 9.87571V15.3504C3 17.0255 4.33415 18.3882 5.97415 18.3882H6.23665V20.9065C6.23665 21.4622 6.67697 21.9119 7.22101 21.9119H9.13468V36.3753C9.13468 41.1309 12.9224 45 17.5788 45C22.2349 45 26.0229 41.1313 26.0229 36.3753V13.2474C26.0229 9.77659 28.7876 6.95278 32.1863 6.95278C35.5844 6.95278 38.3491 9.77659 38.3491 13.2474V26.9493H38.0307C35.6242 26.9493 33.6666 28.9488 33.6666 31.4067V34.284C33.6666 34.8391 34.1075 35.2895 34.651 35.2895H35.4268V38.0953C35.4268 38.6504 35.8677 39.1007 36.4111 39.1007H42.2564C42.7998 39.1007 43.2407 38.6504 43.2407 38.0953V35.2895H44.0156C44.5591 35.2895 45 34.8391 45 34.284V31.4067C45 28.9494 43.0424 26.9493 40.636 26.9493L40.6352 26.949ZM7.42726 5.01075H12.809V8.87022H7.42726V5.01075ZM4.96843 15.3506V10.8813H15.268V15.3506C15.268 15.917 14.8171 16.3775 14.2626 16.3775H5.97371C5.41913 16.3775 4.96828 15.917 4.96828 15.3506H4.96843ZM8.20507 18.3884H12.0323V19.9013L8.20507 19.901V18.3884ZM38.0298 28.9595H40.6352C41.9561 28.9595 43.0305 30.0574 43.0305 31.4061V33.2781H35.6337V31.4061C35.634 30.0575 36.7089 28.9595 38.0298 28.9595ZM41.2703 37.0894H37.3938V35.2889H41.2703V37.0894Z" />
    </svg>
  );
};

export default ConnectedDeviceIcon;
