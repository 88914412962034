import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import get from 'lodash/get';
import uuid from 'uuid';
import axios from 'axios';
import { format } from 'date-fns';

import { dateFormat } from '@uptime/shared/utils/date';
import { parseMilliseconds } from '@uptime/shared/utils/general';
import { ALL_WEEK_DAYS, WHITE_SPACE_REGEXP, TASK_TYPE_MAP } from '@uptime/shared/constants';
import { STATE_NAMES, PROVINCE_NAMES } from '@uptime/shared/constants/states';

const COUNTERS = ['deviceCount', 'taskCount'];

export const parseHoursFromString = (timeValue = '') => timeValue.split(':')[0];

export const parseMinutesFromString = (timeValue = '') => timeValue.split(':')[1];

export const mapInformationData = (props) => {
  const {
    data: { facility },
  } = props;

  if (facility) {
    const { contactId, description, schedule, phoneNumber, officeTypeId, nonWorkingDays } = facility;
    const infoFields = { contactId, description, phoneNumber, officeTypeId, nonWorkingDays };

    return {
      ...infoFields,
      schedule: {
        workingDays: schedule?.workingDays || null,
      },
    };
  }

  return {};
};

export const prepareRequestData = (state = {}) => {
  const { areaData, informationValues, addressValues } = state;
  const {
    description,
    contactId,
    schedule,
    documents,
    image,
    phoneNumber,
    officeTypeId = null,
    nonWorkingDays,
    nonWorkingDaysToSave,
    nonWorkingDaysToSync,
  } = informationValues;
  const holidaysToSync = nonWorkingDaysToSync
    ? nonWorkingDaysToSync
    : // fallback to default nonWorkingDays while Calendar state wasn't set yet
      nonWorkingDays.map((item) => ({ usaDate: item.usaDate }));

  const { coordinates } = addressValues;

  const facilityData = {
    officeTypeId,
    ...omit(addressValues, ['coordinates']),
    contactId,
    ...(isEmpty(phoneNumber) ? { phoneNumber: null } : { phoneNumber }),
    ...(isEmpty(description) ? { description: null } : { description }),
    coordinates: { x: coordinates.longitude, y: coordinates.latitude },
    schedule,
  };

  const mappedAreaData = areaData.map((area) => {
    if (isString(area.id)) {
      return omit(area, ['id', ...COUNTERS]);
    }
    return omit(area, ['__typename', 'type', ...COUNTERS]);
  });

  const filesToSave = documents ? documents.filter((document) => !document.url) : [];
  const imageToSave = image
    ? {
        id: uuid.v4(),
        file: image,
      }
    : null;

  return {
    facilityData,
    nonWorkingDaysToSave: nonWorkingDaysToSave,
    nonWorkingDaysToSync: holidaysToSync,
    areaData: mappedAreaData,
    documents: filesToSave,
    image: imageToSave,
  };
};

export const getContactFields = (formValues, profilesMap, isEmail = false) => {
  const { modified, values, initialValues } = formValues;

  const propToGet = isEmail ? 'email' : 'mobile';

  const isFacilityKeyInOptions = get(profilesMap.current, values.contactId);

  if (!isFacilityKeyInOptions) return '';

  const editCondition = modified.contactId && values.contactId && profilesMap.current;
  const initCondition = profilesMap.current && initialValues.contactId && values.contactId;

  return editCondition || Boolean(initCondition) ? profilesMap.current[values.contactId][propToGet] : '';
};

export const updateAreaList = (areaList = [], area) => {
  const isAreaToUpdate = areaList.find((initArea) => initArea.id === area.id);

  if (Boolean(isAreaToUpdate)) {
    return areaList.map((areaItem) => (areaItem.id === area.id ? area : areaItem));
  }

  return [...areaList, area];
};

export const getGeoCodingOptions = (response) => {
  const { features } = response.data;

  return features.map((feature) => ({
    value: feature.id,
    label: feature.place_name,
    ...feature,
  }));
};

export const getStateByKey = (key) => STATE_NAMES[key] || PROVINCE_NAMES[key] || null;

export const getLocationStateAndPostal = (ctx) => {
  if (!ctx) {
    return {
      postal: null,
      state: null,
      city: null,
    };
  }

  const locationPostcode = ctx.find((item) => item.id.startsWith('postcode'));
  const locationRegion = ctx.find((item) => item.id.startsWith('region'));
  const locationCity = ctx.find((item) => item.id.startsWith('place'));

  const postal = locationPostcode ? locationPostcode.text : null;
  const city = locationCity ? locationCity.text : null;
  const state =
    locationRegion &&
    locationRegion.short_code &&
    ['CA-', 'US-'].some((country) => locationRegion.short_code.startsWith(country))
      ? locationRegion.short_code.split('-')[1]
      : null;

  return {
    postal,
    state,
    city,
  };
};

export const getLocationById = (addressOptions, addressId) =>
  addressOptions.find((address) => address.id === addressId);

export const transformScheduledTask = (data) =>
  [...data.daily.hits, ...data.weekly.hits, ...data.monthly.hits]
    .slice(0, 5)
    .map(({ type, name, schedulerTime }) => ({
      type: TASK_TYPE_MAP[type],
      name,
      schedule: dateFormat(parseMilliseconds(schedulerTime)),
    }));

export const doFetchAddressOptions = async (searchText, setAddressOptions, params = {}) => {
  const search = searchText && searchText.replace(WHITE_SPACE_REGEXP, '+');
  const searchApiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchText}.json`;

  const fetchResult =
    search &&
    (await axios.get(searchApiUrl, {
      params: {
        // TODO:C - replace 'process' by import.meta after migrating to VITE all projects
        access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || process.env.VITE_MAPBOX_ACCESS_TOKEN,
        limit: 7,
        ...params,
      },
    }));

  const options = fetchResult && getGeoCodingOptions(fetchResult);
  setAddressOptions(options);
};

export const transformCoordinates = (location) => {
  if (location) {
    const { lng = 0, lat = 0 } = location;
    return { x: lng, y: lat };
  } else {
    return { x: 0, y: 0 };
  }
};

export const getPolygon = (mapRef) => {
  try {
    const mapBounds = mapRef.current && mapRef.current.getMap().getBounds();
    return {
      topLeft: transformCoordinates(mapBounds.getNorthEast()),
      topRight: transformCoordinates(mapBounds.getNorthWest()),
      bottomLeft: transformCoordinates(mapBounds.getSouthEast()),
      bottomRight: transformCoordinates(mapBounds.getSouthWest()),
    };
  } catch (e) {
    return {
      topLeft: { x: 0, y: 0 },
      topRight: { x: 0, y: 0 },
      bottomLeft: { x: 0, y: 0 },
      bottomRight: { x: 0, y: 0 },
    };
  }
};

export const isDeletableFacility = (item) =>
  Boolean(item && [item.taskCount, item.areaCount, item.deviceCount].every((count) => count === 0));

export const getMutatedParamsForFacility = (params) => {
  const { sort: { column, order: lowerCaseOrder } = {}, ...otherParams } = params || {};

  const order = lowerCaseOrder && lowerCaseOrder.toUpperCase();

  const sort = [
    {
      field: column,
      order,
    },
  ];

  const sortParams = column ? { sort } : {};

  return {
    ...otherParams,
    ...sortParams,
  };
};

export const calculateFacilityWorkingDays = (workingDays) => {
  const facilityWorkingDays = [];
  if (workingDays) {
    const workingDaysKeys = Object.keys(workingDays);
    workingDaysKeys.forEach((day) => {
      if (workingDays[day].isActive) {
        ALL_WEEK_DAYS.forEach((item) => {
          if (item.label === day) {
            facilityWorkingDays.push(item.short);
          }
        });
      }
    });
  }
  return facilityWorkingDays;
};

export const checkForDateCollision = (wasChecked, selectedDays, workingDays, startOn) => {
  const hasDayCollision = checkForDayCollision(wasChecked, selectedDays, workingDays);
  const hasTimeCollision = checkForTimeCollision(startOn, selectedDays, workingDays);

  return hasDayCollision || hasTimeCollision;
};

const checkForDayCollision = (wasChecked, selectedDays, workingDays) => {
  const workingDaysMapped = calculateFacilityWorkingDays(workingDays);
  const difference = selectedDays?.filter((item) => !workingDaysMapped.includes(item));

  if (wasChecked && !isEmpty(workingDays) && difference?.length) {
    return true;
  }
  return false;
};

export const checkForTimeCollision = (startOn, selectedDays, workingDays) => {
  const selectedWorkingDays = {};

  for (const [key, value] of Object.entries(workingDays)) {
    selectedDays.forEach((item) => {
      if (key.toLowerCase().includes(item)) {
        selectedWorkingDays[key] = value;
      }
    });
  }

  let hasCollision = false;
  for (const key in selectedWorkingDays) {
    const dayToCheck = workingDays?.[key];
    const isOpen24 = dayToCheck?.is24Hours;
    const isActive = dayToCheck?.isActive;

    let clockTime = format(startOn, 'kk:mm');
    const openTime = dayToCheck.openTime;
    const closeTime = dayToCheck.closeTime;

    if (clockTime === '24:00') {
      clockTime = '00:00';
    }

    const invalidateBeforeOpen = clockTime < openTime;
    const invalidateAfterClose = clockTime > closeTime;

    if (isActive && !isOpen24 && (invalidateBeforeOpen || invalidateAfterClose)) {
      hasCollision = true; // has hours collision
    }
  }
  return hasCollision;
};

export const calculateDisabledFacilityDate = (
  day,
  days,
  type,
  scheduleOnlyWorkingDays,
  TASK_TYPES,
  workingDays,
  nonWorkingDays
) => {
  const dayToCheck = format(day, 'EEE').toLowerCase();
  let isDisabled = Boolean(days?.indexOf(dayToCheck) === -1) || !days?.length;
  if ((type === TASK_TYPES.m || type === TASK_TYPES.n) && !isEmpty(workingDays) && scheduleOnlyWorkingDays) {
    isDisabled = Boolean(calculateFacilityWorkingDays(workingDays)?.indexOf(dayToCheck) === -1);
  }
  let isNonWorking = false;
  if (nonWorkingDays.length) {
    const dayToCheck = format(day, 'LL/dd/yyyy');
    const nonWorkingDaysArr = nonWorkingDays.reduce((acc, currentVal) => [...acc, currentVal.usaDate], []);
    isNonWorking = nonWorkingDaysArr.includes(dayToCheck);
  }
  return isDisabled || isNonWorking;
};

export const calculateDisabledFacilityHours = (val, view, workingDays) => {
  const dayName = format(val, 'EEEE');
  const dayToCheck = workingDays?.[dayName];
  const isOpen24 = dayToCheck?.is24Hours;
  const isActive = dayToCheck?.isActive;

  if (isOpen24) return false;
  else if (isActive) {
    let clockHour = format(val, 'kk');
    const clockMinute = format(val, 'mm');

    if (clockHour === '24') {
      clockHour = '00';
    }

    const openTime = dayToCheck.openTime;
    const openHour = openTime.slice(0, 2);
    const openMinute = openTime.slice(3, 5);

    const closeTime = dayToCheck.closeTime;
    const closeHour = closeTime.slice(0, 2);
    const closeMinute = closeTime.slice(3, 5);

    if (view === 'hours') {
      const disableBeforeOpen = clockHour < openHour;
      const disableAfterClose = clockHour > closeHour;

      if (disableBeforeOpen || disableAfterClose) {
        return true;
      }
    } else if (view === 'minutes') {
      const firstHour = clockHour === openHour;
      const disableStart = firstHour && clockMinute < openMinute;

      const lastHour = clockHour === closeHour;
      const disableEnd = lastHour && clockMinute > closeMinute;

      if (disableStart || disableEnd) {
        return true;
      }
    } else {
      return false;
    }
  }
};

export const validateFacilityHours = (startOn, selectedDays, workingDays) => {
  const dayName = format(startOn, 'EEEE');
  const dayNameShort = format(startOn, 'EEE').toLowerCase();
  const dayToCheck = workingDays[dayName];
  const isOpen24 = dayToCheck.is24Hours;
  const isActive = dayToCheck.isActive;
  const isSelectedDateWithinSelectedDays = selectedDays.includes(dayNameShort);

  let clockTime = format(startOn, 'kk:mm');
  const openTime = dayToCheck.openTime;
  const closeTime = dayToCheck.closeTime;

  if (clockTime === '24:00') {
    clockTime = '00:00';
  }

  const disableBeforeOpen = clockTime < openTime;
  const disableAfterClose = clockTime > closeTime;

  if (!isSelectedDateWithinSelectedDays) {
    return false;
  } else if (isOpen24) {
    return true;
  } else if (isActive && (disableBeforeOpen || disableAfterClose)) {
    return false;
  }
  return true;
};
